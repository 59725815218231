// JsFromRoutes CacheKey 60bb2194bc765e18c833a882c78a0ace
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/drive'),
  requestAccessToDocument: definePathHelper('post', '/drive/:id/request_access_to_document'),
  addCurrentUserAsParticipant: definePathHelper('post', '/drive/:id/add_current_user_as_participant'),
  templatePackDocuments: definePathHelper('get', '/drive/:id/template_pack_documents'),
}
